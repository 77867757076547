import { useStaticQuery, graphql } from 'gatsby';

const useProfiles = () => {

  const profiles = useStaticQuery( graphql `
        {
          allStrapiEntityProfiles(sort: {fields: updatedAt, order: DESC}) {
            nodes {
              id
              title
              description
              en_description
              category_ass_size {
                name
                en_name
              }
              category_boobs_size {
                name
                en_name
              }
              category_city {
                name
                en_name
              }
              category_contexture {
                name
                en_name
              }
              category_eye_color {
                name
                en_name
              }
              category_hair_color {
                name
                en_name
              }
              category_skin_color {
                name
                en_name
              }
              image_one {
                  sharp: childImageSharp {
                      fluid( maxWidth: 400 , maxHeight: 400 ) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
            }
          }
        }
    `);

    return profiles.allStrapiEntityProfiles.nodes.map( profile => ({
        id: profile.id,
        title: profile.title,
        description: profile.description,
        en_description: profile.en_description,
        skinColor: profile.category_skin_color,
        hairColor: profile.category_hair_color,
        boobSize: profile.category_boobs_size,
        contexture: profile.category_contexture,
        eyeColor: profile.category_eye_color,
        assSize: profile.category_ass_size,
        city: profile.category_city,
        picture: profile.image_one
    }))
}
 
export default useProfiles;
