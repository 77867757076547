import React from 'react'

function getShotDescription( description ){
    const ptag = description !== null && description !== '' ? description.split(' ') : '';
    if(ptag.length > 0 ){
        return ptag.length > 30 ? ptag.slice( 0, 30).join(' ') + '  ...' : ptag.slice( 0, ptag.length ).join(' ');
    }else{
        return '';
    }
}

function isValidHTML(htmlString) {
    if (typeof window !== 'undefined' && typeof DOMParser !== 'undefined') {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const errors = doc.querySelector('parsererror');
        return errors === null;
    } else {
        return false;
    }
}

function cleanHtml( htmlString ) {
    return htmlString.replace(/(background-color|color|font-size):\s*[^;]+;?/gi, '');
}

const ShortHtmltext = ({text}) => {
  
    function createMarkup(text) {
        return { __html: text };
    }

    if( isValidHTML(text) ){
        const cleanedHtml = cleanHtml(text);
        return ( <p style={{ textAlign: 'justify', 'font-size': '1.2rem' }} dangerouslySetInnerHTML={createMarkup(cleanedHtml)}></p> )
    } else {
        return <p>{getShotDescription( text )}</p>
    }
}

export default ShortHtmltext;