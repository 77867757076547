import React from 'react';
import Layout from '../components/layout';
import Banner from '../components/banner';
import Footer from '../components/footer';
import Profiles from '../components/profiles'
import useHomeMetadata from '../hooks/useHomeMetadata';
import useCategories from '../hooks/useCategories';
import usePhones from '../hooks/usePhones';
import Htmltext from '../components/htmlText';


const Index = () => {

    const metadata = useHomeMetadata();
    const categories = useCategories();
    const phones = usePhones();
   
    const textstart = metadata[0].start_text
    const textend = metadata[0].end_text
    return ( 
        <Layout lan='es' >
            <Banner 
                banner_h1={metadata[0].banner_h1}
                banner_h2={metadata[0].banner_h2}
                phones={phones[0].phones}
            />
            <main>
            <Htmltext text={textstart}/>
            <Profiles  lan='es' />
            <Htmltext text={textend}/>
            </main>
            <Footer 
                h3_section={metadata[0].category_h3_section}
                p_section={metadata[0].category_p_section}
                categories={categories}
                lan='es'
            />
        </Layout>
    );
}
 
export default Index;
