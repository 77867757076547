import React, {useState} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';


const Form = styled.form`
    width: 100%;
    background: #efefef;
    /*display: flex;*/
    border: 1px solid #E1E1E1;
    max-width: 1200px;
    margin: 2rem auto 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;

    @media (min-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(5, 1fr);
    }
`;


const FilterCard = styled.div`
    width: 180px;
    background: #fff;
    float: left;
    min-height: 180px;
    padding: 10px;
    font-family: 'Lato', sans-serif;
    margin: 2rem auto 2rem auto;
    p { 
        color: #f40b59; 
        text-transform: uppercase;   
    } 
    input {
        margin-right: 10px;
    }
`;

const DivButton = styled.div`
    text-align: center;
    input {
        max-width: 1200px;
        width: 95%;
        margin: 4rem auto 2rem auto;
        background-color: #881111;
        font-size: 12px;
        padding: 1rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        border: none;
        margin-top: 2rem;
        transition: background-color .3s ease;
        &:hover{
            color: #333;
            background-color: #f45b29;
            cursor: pointer;
        }    
    }

    @media (min-width: 768px) {
        input {
            font-size: 16px;
        }
    }
`;

const DivResetButton = styled.div`
    text-align: center;
    input {
        max-width: 150px;
        width: 95%;
        margin: 4rem auto 2rem auto;
        background-color: #881155;
        font-size: 12px;
        padding: 1rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        border: none;
        margin-top: 2rem;
        transition: background-color .3s ease;
        border: 3px solid #FFFFFF;
        &:hover{
            color: #333;
            background-color: #f45b29;
            cursor: pointer;
        }    
    }

    @media (min-width: 768px) {
        input {
            font-size: 14px;
        }
    }
`;


const useFilter = () => {

    const [ skinColor, setSkinColor] = useState('');
    const [ hairColor, setHairColor] = useState('');
    const [ boobSize, setBoobSize] = useState('');
    const [ contexture, setContexture] = useState('');
    const [ eyeColor, setEyeColor] = useState('');
    const [ assSize, setAssSize] = useState('');
    const [ city, setCity] = useState('');

    const _categories = useStaticQuery(graphql`
        query { 
            allStrapiCategorySkinColor {
                nodes {
                  id
                  name
                  en_name
                }
            }
            allStrapiCategoryHairColors {
                nodes {
                  id
                  name
                  en_name
                }
            }
            allStrapiCategoryBoobsSizes {
                nodes {
                  id
                  name
                  en_name
                }
            }
            allStrapiCategoryContextures {
                nodes {
                  id
                  name
                  en_name
                }
            }
            allStrapiCategoryEyeColor {
                nodes {
                  id
                  name
                  en_name
                }
            }
            allStrapiCategoryAssSize {
                nodes {
                  id
                  name
                  en_name
                }
            }
            allStrapiCategoryCity {
                nodes {
                  id
                  name
                  en_name
                }
            }
        }`
    );

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const reset = () => {
        setSkinColor('');
        setHairColor('');
        setBoobSize('');
        setContexture('');
        setEyeColor('');
        setAssSize('');
        setCity('');
    }
    
    const FilterUI = ( lan ) => (
        <>
            <DivButton>
                <input type="button" onClick={toggle} value={ lan === 'es' ? 'Encuentra tu acompañante perfecta' : 'Find your perfect companion' }/>
            </DivButton>
            
            { !isOpen ? null : 
                <>
                    <Form>
                        <FilterCard>
                            <p>{lan === 'es' ? 'Color de piel' : 'Skin color'}</p>
                            { _categories.allStrapiCategorySkinColor.nodes.map( opcion => (
                                <div key={opcion.id}>
                                    <input 
                                        type="radio" 
                                        value={ lan === 'es' ? opcion.name : opcion.en_name } 
                                        checked={ lan === 'es' ? skinColor === opcion.name : skinColor === opcion.en_name } 
                                        onChange={ e => setSkinColor(e.target.value)}  
                                    />{ lan === 'es' ? opcion.name : opcion.en_name }
                                </div>
                            ))}
                        </FilterCard>   

                        <FilterCard>
                            <p>{lan === 'es' ? 'Color de pelo' : 'Hair color'}</p>
                            { _categories.allStrapiCategoryHairColors.nodes.map( opcion => (
                                <div key={opcion.id}>
                                    <input 
                                        type="radio" 
                                        value={ lan === 'es' ? opcion.name : opcion.en_name } 
                                        checked={ lan === 'es' ? hairColor === opcion.name : hairColor === opcion.en_name } 
                                        onChange={ e => setHairColor(e.target.value)}  
                                    />{ lan === 'es' ? opcion.name : opcion.en_name }
                                </div>
                            ))}
                        </FilterCard>

                        <FilterCard>
                            <p>{lan === 'es' ? 'Tamaño del busto' : 'Boobs size'}</p>
                            <p></p>
                            { _categories.allStrapiCategoryBoobsSizes.nodes.map( opcion => (
                                <div key={opcion.id}>
                                    <input 
                                        type="radio" 
                                        value={ lan === 'es' ? opcion.name : opcion.en_name } 
                                        checked={ lan === 'es' ? boobSize === opcion.name : boobSize === opcion.en_name } 
                                        onChange={ e => setBoobSize(e.target.value)}  
                                    />{ lan === 'es' ? opcion.name : opcion.en_name }
                                </div>
                            ))}
                        </FilterCard>
                        
                        <FilterCard>
                            <p>{lan === 'es' ? 'Contextura' : 'Contexture'}</p>
                            { _categories.allStrapiCategoryContextures.nodes.map( opcion => (
                                <div key={opcion.id}>
                                    <input 
                                        type="radio" 
                                        value={ lan === 'es' ? opcion.name : opcion.en_name } 
                                        checked={ lan === 'es' ? contexture === opcion.name : contexture === opcion.en_name } 
                                        onChange={ e => setContexture(e.target.value)}  
                                    />{ lan === 'es' ? opcion.name : opcion.en_name }
                                </div>
                            ))}
                        </FilterCard>

                        <FilterCard>
                            <p>{lan === 'es' ? 'Color de ojos' : 'Eye Color'}</p>
                            { _categories.allStrapiCategoryEyeColor.nodes.map( opcion => (
                                <div key={opcion.id}>
                                    <input 
                                        type="radio" 
                                        value={ lan === 'es' ? opcion.name : opcion.en_name } 
                                        checked={ lan === 'es' ? eyeColor === opcion.name : eyeColor === opcion.en_name } 
                                        onChange={ e => setEyeColor(e.target.value)}  
                                    />{ lan === 'es' ? opcion.name : opcion.en_name }
                                </div>
                            ))}
                        </FilterCard>
                        
                        <FilterCard>
                            <p>{lan === 'es' ? 'Tamaño de Cola' : 'Ass size'}</p>
                            <p></p>
                            { _categories.allStrapiCategoryAssSize.nodes.map( opcion => (
                                <div key={opcion.id}>
                                    <input 
                                        type="radio" 
                                        value={ lan === 'es' ? opcion.name : opcion.en_name } 
                                        checked={ lan === 'es' ? assSize === opcion.name : assSize === opcion.en_name } 
                                        onChange={ e => setAssSize(e.target.value)}  
                                    />{ lan === 'es' ? opcion.name : opcion.en_name }
                                </div>
                            ))}
                        </FilterCard>

                        <FilterCard>
                            <p></p>
                            <p>{lan === 'es' ? 'Ciudad' : 'City'}</p>
                            { _categories.allStrapiCategoryCity.nodes.map( opcion => (
                                <div key={opcion.id}>
                                    <input 
                                        type="radio" 
                                        value={ lan === 'es' ? opcion.name : opcion.en_name } 
                                        checked={  lan === 'es' ? city === opcion.name : city === opcion.en_name } 
                                        onChange={ e => setCity(e.target.value)}  
                                    />{ lan === 'es' ? opcion.name : opcion.en_name }
                                </div>
                            ))}
                        </FilterCard>

                        
                    </Form>
                    <DivResetButton>
                        { skinColor || hairColor || boobSize || contexture || eyeColor || assSize || city ?
                            <input type="button" onClick={ reset } value={lan === 'es' ? 'Limpiar' : 'Reset'}/> 
                            : null 
                        }
                        { isOpen ?  <input type="button" onClick={ toggle } value={lan === 'es' ? 'Cerrar' : 'Close'}/> 
                            : null 
                        }
                    </DivResetButton>
                    
                </>
            }
        </>
    )

    return {
        skinColor,
        hairColor,
        boobSize,
        contexture,
        eyeColor,
        assSize,
        city,
        FilterUI
    }
}
export default useFilter